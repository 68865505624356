import React, { useState, useRef, useEffect } from "react";
import { Button, CssBaseline, Container } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { clone } from "underscore";
import { Formik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { ActCreators } from "../../redux/bindActionCreator";
import Textbox from "../../components/Textbox/Textbox";
import FileUpload from "../../components/FileUpload/FileUpload";
import { EmployerSignup } from "../../common/module_wise_api_url/onboarding";
import { validateEmployer } from "../../common/module_wise_api_url/employer";
import { commonApiFN } from "../../common/api";
import {
  toastify,
  validateString,
  validateName,
  validateEmail,
  validateLink,
  isStringNumeric,
  transformLowerCaseOnType,
  capitalizeFirstLetter,
} from "../../helper/utils/validation";
import { INTERSTRIDE_LOGO_WHITE } from "../../helper/utils/constants";
import BackPageArrow from "../../assets/svg/back-arrow.svg";

const Signup = (props) => {
  const formRef = useRef();
  const [currentStep, setCurrentStep] = useState(1);

  const history = useHistory();
  const [comp, setComp] = useState({
    companyname: "",
    companywebsite: "",
    linkedinurl: "",
    icon: "",
    file: "",
    firstname: "",
    lastname: "",
    email: "",
    submitted: false,
  });

  const totalSteps = 2;

  const handleCallback = (file) => {
    file.forEach((item) => {
      if (!["image/png", "image/jpg", "image/jpeg"].includes(item.type)) {
        toastify("error", "Please select a valid image. (png, jpg, or jpeg)");
      } else if (parseInt(item.size / 1000) > 5000) {
        toastify("error", "Please select a file < 5 MB");
      } else {
        setComp((prevState) => ({
          ...prevState,
          icon: URL.createObjectURL(item),
          file: file,
        }));
        formRef.current.setFieldTouched("file", true);
      }
    });
  };

  const manipulateStep = (step) => {
    setCurrentStep(step);
  };

  const addEmployer = async (e) => {
    const employerFormData = new FormData();

    if (comp.file && comp.file[0])
      employerFormData.append("logo", comp.file[0]);

    employerFormData.append("name", comp.companyname.trim());
    employerFormData.append("website", comp.companywebsite);
    employerFormData.append("linkedin_url", comp.linkedinurl);
    employerFormData.append("firstname", comp.firstname.trim());
    employerFormData.append("lastname", comp.lastname.trim());
    employerFormData.append("email", comp.email.trim());
    employerFormData.append("frontend_url", window.location.origin);
    employerFormData.append("frontendsteps", currentStep);

    var employerData = clone(EmployerSignup);
    employerData.data = employerFormData;

    const response = await commonApiFN(employerData);

    if (response.success) {
      setCurrentStep(3);
    } else {
      toastify("newerror", response.message);
    }
  };

  const employerValidate = async (e) => {
    const employerFormvalidate = new FormData();

    if (comp.file && comp.file[0])
      employerFormvalidate.append("logo", comp.file[0]);

    employerFormvalidate.append("name", comp.companyname.trim());
    employerFormvalidate.append("website", comp.companywebsite);
    employerFormvalidate.append("linkedin_url", comp.linkedinurl);

    var employerData = clone(validateEmployer);
    employerData.data = employerFormvalidate;

    const response = await commonApiFN(employerData);

    if (response.success) {
      setCurrentStep(2);
    } else {
      toastify("newerror", response.message);
    }
  };

  return (
    <section className="OnboardingWrapper EmployersWrapper Signup">
      <CssBaseline />
      <div className="BrandLogo text-center">
        <a href="/">
          <img src={INTERSTRIDE_LOGO_WHITE} style={{ height: 36 }} alt="Logo" />
        </a>
      </div>

      <Formik
        innerRef={formRef}
        initialValues={{
          companyname: "",
          companywebsite: "",
          linkedinurl: "",
          firstname: "",
          lastname: "",
          email: "",
          file: "",
        }}
        validate={() => {
          const errors = {};
          if (currentStep === 1) {
            if (validateString(comp.companyname)) {
              errors.companyname = "Please enter company name";
            } else if (comp.companyname.length < 2) {
              errors.companyname = "Please enter minimum 2 charcter";
            } else if (isStringNumeric(comp.companyname)) {
              errors.companyname = "Company name can not contain only numbers";
            }

            if (validateString(comp.companywebsite)) {
              errors.companywebsite = "Please enter company website";
            } else if (validateLink(comp.companywebsite)) {
              errors.companywebsite = "Please enter valid website";
            }

            if (validateString(comp.linkedinurl)) {
              errors.linkedinurl = "Please enter LinkedIn URL";
            } else if (validateLink(comp.linkedinurl)) {
              errors.linkedinurl = "Please enter valid URL";
            }
          } else if (currentStep === 2) {
            if (validateString(comp.email)) {
              errors.email = "Please enter email";
            } else if (validateEmail(comp.email)) {
              errors.email = "Please enter valid email";
            }

            if (validateString(comp.firstname)) {
              errors.firstname = "Please enter first name";
            } else if (validateName(comp.firstname)) {
              errors.firstname = "Please enter valid first name";
            }

            if (validateString(comp.lastname)) {
              errors.lastname = "Please enter last name";
            } else if (validateName(comp.lastname)) {
              errors.lastname = "Please enter valid last name";
            }
          }
          return errors;
        }}
        onSubmit={() => {
          if (currentStep < totalSteps) {
            if (currentStep === 1) employerValidate();
          } else addEmployer();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            className="form"
            maxWidth="xs"
            onSubmit={handleSubmit}
            style={{ minHeight: "100vh" }}
          >
            {currentStep === 1 && (
              <>
                <h1 className="text-center">
                  Make an account for your <br /> company
                </h1>
                <Container component="main" maxWidth="xs">
                  <div className="form-group small-fileupload-wrap">
                    <FileUpload
                      name="file"
                      text="Upload company logo"
                      handleUploadFile={handleCallback}
                      imagePreview={true}
                      icon={comp.icon}
                      type={comp.file ? comp.file[0].type : undefined}
                    />
                  </div>

                  <div className="form-group">
                    <Textbox
                      variant="outlined"
                      fullWidth
                      id="companyname"
                      label="Company name"
                      placeholder="Company name"
                      name="companyname"
                      autoComplete="companyname"
                      maxLength={50}
                      value={comp.companyname}
                      onChange={(e) =>
                        setComp({
                          ...comp,
                          companyname: e.target.value,
                        })
                      }
                      onBlur={handleBlur}
                      error={errors.companyname && touched.companyname}
                      errorText={errors.companyname}
                    />
                  </div>

                  <div className="form-group">
                    <Textbox
                      variant="outlined"
                      required
                      fullWidth
                      id="companywebsite"
                      label="Company website"
                      placeholder="Company website"
                      name="companywebsite"
                      autoComplete="companywebsite"
                      type="text"
                      maxLength={50}
                      value={comp.companywebsite}
                      onChange={(e) =>
                        setComp({
                          ...comp,
                          companywebsite: transformLowerCaseOnType(
                            e.target.value
                          ),
                        })
                      }
                      onBlur={handleBlur}
                      error={errors.companywebsite && touched.companywebsite}
                      errorText={errors.companywebsite}
                    />
                  </div>

                  <div className="form-group">
                    <Textbox
                      variant="outlined"
                      required
                      fullWidth
                      id="linkedinurl"
                      label="LinkedIn URL"
                      placeholder="LinkedIn URL"
                      name="linkedinurl"
                      autoComplete="linkedinurl"
                      type="text"
                      maxLength={5000}
                      value={comp.linkedinurl}
                      onChange={(e) =>
                        setComp({
                          ...comp,
                          linkedinurl: e.target.value,
                        })
                      }
                      onBlur={handleBlur}
                      error={errors.linkedinurl && touched.linkedinurl}
                      errorText={errors.linkedinurl}
                    />
                  </div>
                </Container>

                <div className="big-btn">
                  <Button
                    type="submit"
                    className="btn btn-negative"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Next
                  </Button>
                </div>

                <h2 className="text-center">
                  Already have an account on Interstride?
                </h2>

                <div className="big-btn mt-20 mb-0">
                  <div className="mb-0">
                    <Link to="#">
                      <Button
                        type="button"
                        fullWidth
                        color="primary"
                        className="btn btn-active"
                        onClick={() => {
                          if (props?.transition) {
                            props.onLoginButtonClick();
                          } else {
                            history.push("/");
                          }
                        }}
                      >
                        Log in
                      </Button>
                    </Link>
                  </div>
                </div>
              </>
            )}

            {/* Step 2 start     */}
            {currentStep === 2 && (
              <>
                <h1 className="text-center mb-15">Add an account owner</h1>
                <h3 className="page-subTitle text-center mb-50" variant="h3">
                  This will be the main point of contact of your company
                </h3>
                <Container component="main" maxWidth="xs">
                  <div className="form-group">
                    <Textbox
                      variant="outlined"
                      fullWidth
                      id="firstname"
                      placeholder="First name"
                      name="firstname"
                      autoComplete="firstname"
                      autoFocus
                      maxLength={50}
                      value={comp.firstname}
                      onChange={(e) =>
                        setComp({
                          ...comp,
                          firstname: capitalizeFirstLetter(e.target.value),
                        })
                      }
                      onBlur={handleBlur}
                      error={errors.firstname && touched.firstname}
                      errorText={errors.firstname}
                    />
                  </div>

                  <div className="form-group">
                    <Textbox
                      variant="outlined"
                      fullWidth
                      name="lastname"
                      placeholder="Last name"
                      type="text"
                      id="lastname"
                      maxLength={50}
                      value={comp.lastname}
                      onChange={(e) =>
                        setComp({
                          ...comp,
                          lastname: e.target.value,
                        })
                      }
                      onBlur={handleBlur}
                      error={errors.lastname && touched.lastname}
                      errorText={errors.lastname}
                    />
                  </div>

                  <div className="form-group">
                    <Textbox
                      variant="outlined"
                      fullWidth
                      name="email"
                      placeholder="Enter email to invite"
                      type="text"
                      id="email"
                      maxLength={50}
                      value={comp.email}
                      onChange={(e) =>
                        setComp({
                          ...comp,
                          email: e.target.value,
                        })
                      }
                      onBlur={handleBlur}
                      error={errors.email && touched.email}
                      errorText={errors.email}
                    />
                  </div>

                  <div className="big-btn mb-15">
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className="btn btn-negative"
                    >
                      submit
                    </Button>
                  </div>
                  <div className="back-div">
                    <a
                      onClick={() => {
                        manipulateStep(1);
                      }}
                    >
                      <img src={BackPageArrow} alt="back page arrow" /> Back to
                      company
                    </a>
                  </div>
                </Container>
              </>
            )}

            {/* Step 3 start     */}
            {currentStep === 3 && (
              <>
                <h1 className="text-center mb-15">
                  Thanks for applying to our <br /> Employer Portal!
                </h1>
                <h4 className="page-subTitle text-center mb-50" variant="h3">
                  We will review your detail and get back to you <br />
                  within 48 hours.
                </h4>
                <Container component="main" maxWidth="xs">
                  <div className="big-btn">
                    <Link to="#">
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="btn btn-negative"
                        onClick={() => {
                          if (props?.transition) {
                            props.onLoginButtonClick();
                          } else {
                            history.push("/");
                          }
                        }}
                      >
                        Back to Home
                      </Button>
                    </Link>
                  </div>
                </Container>
              </>
            )}
          </form>
        )}
      </Formik>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    globalState: { ...state.loginReducer },
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
