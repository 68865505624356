import React from "react";
import { toast, Slide } from "react-toastify";
import { isEmpty, isNull, isString } from "underscore";
import { Parser } from "json2csv";
//import moment from "moment";

/*import { store } from "../redux/store";

export const getSelectedSchoolId = () => {
  const state = store.getState();
  return get(state, ["loginReducer", "selectedSchool", "id"], null);
};*/

export const toastify = (
  type = "success",
  message = "",
  autoClose = 2000,
  toastId = 1
) => {
  switch (type) {
    case "success":
      toast.success(
        <div className="d-flex align-items-center">
          {/* {getToastifyIcon(type)} */}
          <div>{message}</div>
        </div>,
        {
          autoClose: autoClose,
          position: toast.POSITION.BOTTOM_CENTER,
          transition: Slide,
          closeOnClick: true,
          toastId: toastId,
        }
      );
      break;
    case "error":
      toast.error(
        // <div className="d-flex align-items-center">
        // {/* {getToastifyIcon(type)} */}
        //   &nbsp;{" "}
        <div style={{ display: "block", width: "max-content", color: "#fff" }}>
          {message ? message : "Something went wrong!"}
        </div>,
        // </div>,
        {
          autoClose: autoClose,
          // style: { width: "max-content" },
          position: toast.POSITION.BOTTOM_CENTER,
          transition: Slide,
          closeOnClick: true,
          toastId: toastId,
        }
      );
      break;
    case "info":
      toast.info(
        <div className="d-flex align-items-center">
          {/* {getToastifyIcon(type)} */}
          &nbsp;{" "}
          <div style={{ display: "block", width: "max-content" }}>
            {message}
          </div>
        </div>,
        {
          autoClose: autoClose,
          style: { width: "max-content" },
          position: toast.POSITION.BOTTOM_CENTER,
          transition: Slide,
          closeOnClick: true,
          toastId: toastId,
        }
      );
      break;
    case "warning":
      toast.warning(
        <div className="d-flex align-items-center">
          {/* {getToastifyIcon(type)} */}
          &nbsp;{" "}
          <div style={{ display: "block", width: "max-content" }}>
            {message}
          </div>
        </div>,
        {
          autoClose: autoClose,
          style: { width: "max-content" },
          position: toast.POSITION.BOTTOM_CENTER,
          transition: Slide,
          closeOnClick: true,
          toastId: toastId,
        }
      );
      break;
    case "newerror":
      toast.error(
        // <div className="d-flex align-items-center">
        // {/* {getToastifyIcon(type)} */}
        //   &nbsp;{" "}
        // <div style={{ display: "block", width: "max-content", color: "#fff" }}>
        //   {message ? message : "Something went wrong!"}
        // </div>,
        <div>
          {message &&
            message.length &&
            message.map((element) => (
              <div
                style={{
                  display: "block",
                  width: "max-content",
                  color: "#fff",
                }}
              >
                {element ? element : "Something went wrong!"}
              </div>
            ))}
        </div>,
        {
          autoClose: autoClose,
          // style: { width: "max-content" },
          position: toast.POSITION.BOTTOM_CENTER,
          transition: Slide,
          closeOnClick: true,
          toastId: toastId,
        }
      );
      break;
    default:
      break;
  }
};

export const exportData = ({ data = [], fields = [], filename = "data" }) => {
  const json2csvParser = new Parser({ fields, delimiter: "," });
  const csv = json2csvParser.parse(data);

  const date = new Date();
  const [month, day, year] = [
    ("0" + (date.getMonth() + 1)).slice(-2),
    date.getDate(),
    date.getFullYear(),
  ];

  downloadFile(
    `${filename}_${year}${month}${day}.csv`,
    "data:text/csv;charset=UTF-8," + encodeURIComponent(csv)
  );
};

const downloadFile = (fileName, urlData) => {
  try {
    let aLink = document.createElement("a");
    aLink.download = fileName;
    aLink.href = urlData;

    let event = new MouseEvent("click");
    aLink.dispatchEvent(event);

    toastify("success", "Your list was exported successfully.");
  } catch (e) {
    toastify("error", "Unable to download file!");
  }
};

export const downloadAttachment = (fileName, urlData) => {
  try {
    let aLink = document.createElement("a");
    aLink.download = fileName;
    aLink.href = urlData;

    let event = new MouseEvent("click");
    aLink.dispatchEvent(event);

    toastify("success", "Your list was exported successfully.");
  } catch (e) {
    toastify("error", "Unable to download file!");
  }
};

export const isStringNumeric = (num) => {
  return !isNaN(num);
};

export const validateString = (value = "") => {
  return !value || isEmpty(value) || isEmpty(value.trim());
};

export const validateId = (value) => {
  return (
    !value || isEmpty(value.toString()) || isEmpty(value.toString().trim())
  );
};

export const validateNumber = (value = "") => {
  return !value || isEmpty(value);
};

export const NAME_REGEX =
  /^[a-zA-ZÀ-ÿÇçŒœÆæ\s]+(([-_'][a-zA-ZÀ-ÿÇçŒœÆæ]{1,})+)?$/i;

export const validateName = (text) =>
  text && NAME_REGEX.test(text.trim()) ? false : true;

export const validateNameNum = (value = "") => {
  if (isString(value)) {
    return value.trim().match(/^[A-Za-z0-9./#&+-\s]+$/) ? false : true;
  }
};

export const validatePassword = (value = "") => {
  return value.trim().match(/(?=.*?[A-Z])(?=.*?[a-z])/) ? false : true;
};

export const validateEmail = (value = "") => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value.trim())
    ? false
    : true;
};

export const validateArray = (value = []) => {
  return !value || isEmpty(value);
};

export const validateObject = (value = []) => {
  return !value || isEmpty(value) || isNull(value);
};

export const validateFile = (value = []) => {
  return !value || isEmpty(value);
};

export const validateUrl = (value = "") => {
  var expres = new RegExp("^(?:[a-z]+:)?//", "i");
  return expres.test(value) ? false : true;
};

export const validateLink = (value = "") => {
  return !isEmpty(value.trim()) &&
    /(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/g.test(
      value.trim()
    )
    ? false
    : true;
};

export const validateJobUrl = (value = "") => {
  let expres = new RegExp(
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  );
  return expres.test(value) ? false : true;
};

export const validateYear = (value = "") => {
  return /^\d{4}$/.test(value) ? false : true;
};

export const getValidSearchText = (value = "") => {
  return value.replace(/[^a-zA-Z0-9\s@.~_-]/g, "");
};

/*const createTime = (date, number, add) => {
  return moment(date).add(number, add).format("hh:mma");
};

export const getWebinarTimeStamp = (start_time, duration, timezone) => {
  return `${moment(start_time).format("hh:mma")}-${createTime(
    start_time,
    duration,
    "minutes"
  )} (${timezone})`;
};*/

export const capitalizeFirstLetter = (string = "") => {
  if (string && string.trim().length > 0)
    return string.charAt(0).toUpperCase() + string.slice(1);
  else return "";
};

export const transformLowerCaseOnType = (string = "") => {
  if (string && string.trim().length > 0) return string.toLowerCase();
  else return "";
};

export const getInitials = (title, initialCount = 2) => {
  const matches = title && title != null ? title.match(/\b(\w)/g) : [];
  if (matches && matches != null) {
    return matches.splice(0, initialCount).join("").toLocaleUpperCase();
  }
  return "";
};

export const getClickableLink = (link = "") => {
  return link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `http://${link}`;
};
